import React, { useRef, useState, useEffect } from "react";
import CrossIcon from "../icons/CrossIcon";
import "../styles/Modal.scss";
import { useMask } from "@react-input/mask";
import { message } from "antd";
import { sendMessage } from "../api/telegram";

const messages = [
  {
    messageSuccess: "Ваше повідомлення успішно надіслано!",
  },
  {
    messageError: "Заповніть усі поля!",
  },
];

const Modal = ({ openModal }: any) => {
  const [messageApi, contextHolder] = message.useMessage();
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useMask({ mask: "+38(___)-___-__-__", replacement: { _: /\d/ } });

  const messageSuccess = () => {
    messageApi.open({
      type: "success",
      content: messages[0].messageSuccess,
      className: "Message",
    });
  };

  const messageError = () => {
    messageApi.open({
      type: "error",
      content: messages[1].messageError,
      className: "Message",
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
  });

  const handleChange = (e: any) => {
    const { name, value, files } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      if (formValues.name && formValues.phone) {
        console.log(formValues.phone);
        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${formValues.phone}`;

        await sendMessage(message);
        messageSuccess();
      } else {
        messageError();
      }
    } catch (e) {
      console.log("Error");
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.classList.add("closed");
    }
    setTimeout(() => {
      openModal();
    }, 300);
  };
  return (
    <div className="Modal">
      {contextHolder}
      <div className="ModalOverlay" onClick={closeModal}></div>
      <div className="ModalBody" ref={modalRef}>
        <div className="ModalHeader">
          <button onClick={closeModal}>
            <CrossIcon></CrossIcon>
          </button>
        </div>
        <div className="ModalTitle">
          <h1>Заповніть форму для зворотного зв'язку</h1>
        </div>
        <form onSubmit={handleSubmit} className="ModalForm">
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Ім’я"
            value={formValues.name}
            onChange={handleChange}
            required
          />
          <input
            id="phone"
            name="phone"
            type="tel"
            placeholder="Телефон"
            ref={inputRef}
            value={formValues.phone}
            onChange={handleChange}
            required
          />
          <button className="btn" type="submit">
            <img src={process.env.PUBLIC_URL + "/icons/btnStarIcon.svg"} alt="" />
            Безкоштовний урок
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;

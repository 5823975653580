import React, { useState } from "react";
import "../styles/Footer.scss";
import { useMask } from "@react-input/mask";
import { message } from "antd";
import { sendMessage } from "../api/telegram";

const messages = [
  {
    messageSuccess: "Ваше повідомлення успішно надіслано!",
  },
  {
    messageError: "Заповніть усі поля!",
  },
]

const Footer = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const inputRef = useMask({ mask: "+38(___)-___-__-__", replacement: { _: /\d/ } });

  const messageSuccess = () => {
    messageApi.open({
      type: "success",
      content: messages[0].messageSuccess,
      className: "Message",
    });
  };

  const messageError = () => {
    messageApi.open({
      type: "error",
      content: messages[1].messageError,
      className: "Message",
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
  });

  const handleChange = (e: any) => {
    const { name, value, files } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
      
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      if (formValues.name && formValues.phone) {
        console.log(formValues.phone);
        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${formValues.phone}`;

        await sendMessage(message);
        messageSuccess();
      } else {
        messageError();
      }

    } catch (e) {
      console.log("Error");
    } finally {
      setIsLoading(false);
    }
  };

  function CurrentYear() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    return <span>{currentYear}</span>;
  }

  return (
    <section className="footer" id="footer">
      {contextHolder}
      <div className="footer__content wrapper">
        <div className="footer__info">
          <div className="footer__forms">
            <form
            onSubmit={handleSubmit}
            >
              <input
                id="name"
                name="name"
                type="text"
                placeholder="Ім’я"
                value={formValues.name}
                onChange={handleChange}
                required
              />
              <input
                id="phone"
                name="phone"
                type="tel"
                placeholder="Телефон"
                ref={inputRef}
                value={formValues.phone}
                onChange={handleChange}
                required
              />
              <button className="btn" type="submit">
                <img src={process.env.PUBLIC_URL + "/icons/btnStarIcon.svg"} alt="" />
                Безкоштовний урок
              </button>
            </form>
          </div>
          <div className="footer__contacts">
            <h3>Контакти</h3>
            <a className="footer__tel" href="tel:+380962942077">
              +38(096)-294-20-77
            </a>
            <a className="footer__mail" href="mailto:quickschool.ua@gmail.com">
              quickschool.ua@gmail.com
            </a>
            <div className="footer__socials">
              <a href="https://www.instagram.com/quickschool.ua?igsh=MjY3NG50cGVoazQ=">
                <img src={process.env.PUBLIC_URL + "/icons/inst.svg"} alt="instagram" />
              </a>
              <a href="https://t.me/+380962942077">
                <img src={process.env.PUBLIC_URL + "/icons/tg.svg"} alt="telegram" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer__docs">
          <img className="footer__logo-bg" src={process.env.PUBLIC_URL + "/bgs/footerBg.svg"} alt="" />
          <img className="footer__logo" src={process.env.PUBLIC_URL + "/logo.svg"} alt="" />
          <div className="footer__links">
            <a
              href={process.env.PUBLIC_URL + "/Політика Конфіденційності.docx"}
              download="Політика Конфіденційності.docx"
              className="footer__link"
            >
              Політика конфіденційності
            </a>
            <a
              href={process.env.PUBLIC_URL + "/Договір оферти.docx"}
              download="Договір оферти.docx"
              className="footer__link"
            >
              Договір оферти
            </a>
          </div>
        </div>
      </div>
      <div className="footer__copyright wrapper">
        <span className="copyright">
          <CurrentYear /> © Quick School, Всі права захищені.
        </span>
        <span className="copyright">
          Developed by
          <a href="https://abc-digital.com/"> ABC Digital</a>
        </span>
      </div>
    </section>
  );
};

export default Footer;